import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth';
import { Store } from '@ngxs/store';
import { filter, tap } from 'rxjs';

import { GoalCountersState } from './goal-counters.state';
import { GoalCountersStateActions } from './goal-counters.state.actions';

@Injectable({ providedIn: 'root' })
export class GoalCountersStateService {
  waitModerationCount$ = this.store.select(GoalCountersState.waitModerationCount);

  newPayoutCount$ = this.store.select(GoalCountersState.newPayoutCount);

  waitReModerationCount$ = this.store.select(GoalCountersState.waitReModerationCount);

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store,
  ) {}

  public init(): void {
    this.authService.profile$
      .pipe(
        filter(Boolean),
        tap(() => this.load()),
      )
      .subscribe();
  }

  public load(): void {
    this.store.dispatch(new GoalCountersStateActions.Load());
  }
}
