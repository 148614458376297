import { GoalPayoutStatusEnum } from '../../payout';

export interface GoalFiltersDto {
  search?: string | null;
  title?: string | null;
  subtitle?: string | null;
  regionId?: string | null;
  region?: string | null;
  status?: string | null;
  activeUntilLte?: string | null;
  activeUntilGte?: string | null;
  activeUntil?: boolean | null;
  userId?: string | null;
  amountLte?: number | null;
  amountGte?: number | null;
  collectedLte?: number | null;
  collectedGte?: number | null;
  ids?: string[] | null;
  hasPayoutRequestWithStatuses?: GoalPayoutStatusEnum[] | null;
}

export type GoalFindOrderKey =
  | 'createdAt'
  | '-createdAt'
  | 'updatedAt'
  | '-updatedAt'
  | 'activeUntil'
  | '-activeUntil'
  | 'startedAt'
  | '-startedAt'
  | null;

export interface GoalFindRequestDto extends GoalFiltersDto {
  onPage?: number | null;
  page?: number | null;
  order?: GoalFindOrderKey | null;
}
