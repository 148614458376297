import { Injectable } from '@angular/core';
import { GoalApiService, GoalFiltersDto, GoalStatusEnum } from '@app/core/api/goal';
import { GoalPayoutStatusEnum } from '@app/core/api/payout';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, forkJoin, map, Observable, of, tap } from 'rxjs';

import { GoalCountersStateActions } from './goal-counters.state.actions';

interface GoalCountersStateModel {
  waitModerationCount: number | null;
  newPayoutCount: number | null;
  waitReModerationCount: number | null;
}

const EMPTY_STATE: GoalCountersStateModel = {
  waitModerationCount: null,
  newPayoutCount: null,
  waitReModerationCount: null,
};

@State<GoalCountersStateModel>({
  name: 'goalCounters',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class GoalCountersState {
  @Selector()
  public static waitModerationCount(state: GoalCountersStateModel): number | null {
    return state.waitModerationCount;
  }

  @Selector()
  public static waitReModerationCount(state: GoalCountersStateModel): number | null {
    return state.waitReModerationCount;
  }

  @Selector()
  public static newPayoutCount(state: GoalCountersStateModel): number | null {
    return state.newPayoutCount;
  }

  constructor(private readonly goalApi: GoalApiService) {}

  @Action(GoalCountersStateActions.Load, { cancelUncompleted: true })
  public loadNewCount({ patchState }: StateContext<GoalCountersStateModel>): Observable<(number | null)[]> {
    return forkJoin([
      this.fetchGoalsCount({ status: GoalStatusEnum.WaitModeration }),
      this.fetchGoalsCount({ hasPayoutRequestWithStatuses: [GoalPayoutStatusEnum.Created] }),
      this.fetchGoalsCount({ status: GoalStatusEnum.WaitReModeration }),
    ]).pipe(
      tap(([waitModerationCount, newPayoutCount, waitReModerationCount]) =>
        patchState({ waitModerationCount, newPayoutCount, waitReModerationCount }),
      ),
    );
  }

  protected fetchGoalsCount(filter: GoalFiltersDto): Observable<number | null> {
    return this.goalApi
      .find({
        ...filter,
        onPage: 1,
        page: 0,
      })
      .pipe(
        map((response) => response.data.total),
        catchError(() => of(null)),
      );
  }
}
