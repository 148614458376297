export interface GoalPayoutsFilterDto {
  goalId?: string | null;
  id?: string | null;
  userId?: string | null;
  active?: boolean | null;
  completed?: boolean | null;
}

export interface GoalPayoutsRequestDto extends GoalPayoutsFilterDto {
  onPage?: number | null;
  page?: number | null;
}
