export enum GoalPayoutStatusEnum {
  Created = 'created',
  InProgress = 'in-progress',
  Completed = 'completed',
  Declined = 'declined',
  Error = 'error',
}

export interface GoalPayoutDto {
  id: string;
  goalId: string;
  userId: string;
  status: GoalPayoutStatusEnum;
  collected: number;
  commission: number;
  total: number;
  createdAt: string;
  updatedAt: string;
}
