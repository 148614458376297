const REGEX_SPECIALS = [
  // order matters for these
  '-',
  '[',
  ']',
  // order doesn't matter for any of these
  '/',
  '{',
  '}',
  '(',
  ')',
  '*',
  '+',
  '?',
  '.',
  '\\',
  '^',
  '$',
  '|',
];

export function escapeRegExp(str: string, exclude?: string[]): string {
  const specials = exclude?.length ? REGEX_SPECIALS.filter((special) => !exclude.includes(special)) : REGEX_SPECIALS;

  const regex = new RegExp('[' + specials.join('\\') + ']', 'g');
  return str.replace(regex, '\\$&');
}
