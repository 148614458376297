import { RouterLinkActive } from '@angular/router';

import { AppAbilityType } from '../auth/services/app-ability.factory';

export type SidenavRouteFn = () => string;

export type SidenavCounter = 'complaints' | 'goals';

interface SidenavItemLiteral {
  name: string;
  svgIcon?: string;
  route: string | null;
  parent?: SidenavItemLiteral;
  subItems?: SidenavItemLiteral[];
  position: number;
  badge?: string;
  badgeColor?: string;
  customClass?: string;
  routerLinkActiveOptions?: RouterLinkActive['routerLinkActiveOptions'];
  disabled?: boolean;
  counter?: SidenavCounter;
  accessHandler?: (ability: AppAbilityType) => boolean;
}

export class SidenavItem implements SidenavItemLiteral {
  name!: string;

  svgIcon?: string;

  route!: string | null;

  parent?: SidenavItem;

  subItems?: SidenavItem[];

  position!: number;

  badge?: string;

  badgeColor?: string;

  customClass?: string;

  routerLinkActiveOptions?: RouterLinkActive['routerLinkActiveOptions'];

  disabled?: boolean;

  counter?: SidenavCounter;

  accessHandler: (ability: AppAbilityType) => boolean = () => true;

  constructor(model?: SidenavItemLiteral) {
    if (model) {
      const { subItems, ...modelParams } = model;
      Object.assign(this, modelParams);
      this.subItems = this.mapSubItems(subItems);
    }
  }

  public hasSubItems(): boolean {
    if (this.subItems) {
      return this.subItems.length > 0;
    }

    return false;
  }

  public hasParent(): boolean {
    return !!this.parent;
  }

  public mapSubItems(list?: SidenavItemLiteral[]): SidenavItem[] | undefined {
    if (list) {
      return list.map((item) => new SidenavItem(item));
    }

    return list;
  }

  public generateLetterIcon(): string {
    const words = this.name.split(' ');

    if (words.length > 1) {
      return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toLowerCase();
    } else {
      return this.name.charAt(0).toUpperCase() + this.name.charAt(1).toLowerCase();
    }
  }
}
