export function stripRight(path: string): string {
  return path.replace(new RegExp(/\/$/), '');
}

export function stripLeft(path: string): string {
  return path.replace(new RegExp(/^\//), '');
}

export function strip(path: string): string {
  return stripLeft(stripRight(path));
}

export function joinUrl(...segments: string[]): string {
  return segments
    .filter((p) => !!p)
    .map((p, i) => (i > 0 ? strip(p) : stripRight(p)))
    .join('/');
}
