import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { SidenavItem } from '../sidenav-item.model';
import { SIDENAV_MENU_ITEMS } from '../sidenav-menu-items.const';
import {
  AddSidenavItemAction,
  RemoveSidenavItemAction,
  SetCurrentlyOpenByRouteAction,
  ToggleOpenSidenavItemAction,
} from './sidenav.actions';

export interface SidenavStateModel {
  sidenavItems: SidenavItem[];
  currentlyOpen: SidenavItem[];
}

const SIDENAV_STATE_EMPTY: SidenavStateModel = {
  sidenavItems: [...SIDENAV_MENU_ITEMS],
  currentlyOpen: [],
};

function getAllParentItems(item: SidenavItem, currentlyOpenTemp: SidenavItem[] = []): SidenavItem[] {
  currentlyOpenTemp.unshift(item);

  if (item.hasParent()) {
    return getAllParentItems(item.parent as SidenavItem, currentlyOpenTemp);
  } else {
    return currentlyOpenTemp;
  }
}

function findByRouteRecursive(route: string, collection: SidenavItem[]): SidenavItem | null {
  let result = collection.find((item) => item.route === route);

  if (!result) {
    collection.forEach((item) => {
      if (item.hasSubItems()) {
        const found = findByRouteRecursive(route, item.subItems || []);

        if (found) {
          result = found;
        }
      }
    });
  }

  return result ?? null;
}

@State<SidenavStateModel>({
  name: 'sidenav',
  defaults: { ...SIDENAV_STATE_EMPTY },
})
@Injectable()
export class SidenavState {
  @Selector()
  public static getSidenavItems(state: SidenavStateModel): SidenavItem[] {
    return state.sidenavItems;
  }

  @Selector()
  public static getSidenavCurrentlyOpen(state: SidenavStateModel): SidenavItem[] {
    return state.currentlyOpen;
  }

  @Action(AddSidenavItemAction)
  public addSidenavItemAction(ctx: StateContext<SidenavStateModel>, { payload }: AddSidenavItemAction): void {
    const item = payload;
    const sidenavItems = ctx.getState().sidenavItems;

    if (sidenavItems.indexOf(item) === -1) {
      ctx.patchState({
        sidenavItems: [...sidenavItems, item],
      });
    }
  }

  @Action(RemoveSidenavItemAction)
  public removeSidenavItemAction(ctx: StateContext<SidenavStateModel>, { payload }: RemoveSidenavItemAction): void {
    const item = payload;
    const sidenavItems = ctx.getState().sidenavItems;
    ctx.patchState({
      sidenavItems: sidenavItems.filter((stateItem) => stateItem !== item),
    });
  }

  @Action(ToggleOpenSidenavItemAction)
  public toggleOpenSidenavItemAction(
    ctx: StateContext<SidenavStateModel>,
    { payload }: ToggleOpenSidenavItemAction,
  ): void {
    const item = payload;
    let currentlyOpen = ctx.getState().currentlyOpen;

    if (currentlyOpen.indexOf(item) > -1) {
      if (currentlyOpen.length > 1) {
        currentlyOpen = currentlyOpen.slice(0, currentlyOpen.indexOf(item));
      } else {
        currentlyOpen = [];
      }
    } else {
      currentlyOpen = getAllParentItems(item);
    }

    ctx.patchState({
      currentlyOpen,
    });
  }

  @Action(SetCurrentlyOpenByRouteAction)
  public setCurrentlyOpenByRouteAction(
    ctx: StateContext<SidenavStateModel>,
    { payload }: SetCurrentlyOpenByRouteAction,
  ): void {
    const route = payload;
    let currentlyOpen: SidenavItem[] = [];

    const sidenavItems = ctx.getState().sidenavItems;
    const item = findByRouteRecursive(route, sidenavItems);

    if (item && item.hasParent()) {
      currentlyOpen = getAllParentItems(item.parent as SidenavItem);
    } else if (item) {
      currentlyOpen = [item];
    }

    ctx.patchState({
      currentlyOpen,
    });
  }
}
