import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { GoalCountersState } from './goal-counters.state';
import { GoalCountersStateService } from './goal-counters-state.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxsModule.forFeature([GoalCountersState])],
})
export class GoalCountersStateModule {
  public static forRoot(): ModuleWithProviders<GoalCountersStateModule> {
    return {
      ngModule: GoalCountersStateModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (service: GoalCountersStateService) => () => service.init(),
          deps: [GoalCountersStateService],
          multi: true,
        },
      ],
    };
  }
}
