import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPaginatedDto, ApiResponseDto } from '@app/core/api/base-dtos';
import { buildHttpParams } from '@app/shared/utils';
import { Observable } from 'rxjs';

import { GoalPayoutDto, GoalPayoutsRequestDto } from './dtos';

@Injectable({ providedIn: 'root' })
export class PayoutApiService {
  constructor(private readonly http: HttpClient) {}

  public getPayouts(request: GoalPayoutsRequestDto): Observable<ApiResponseDto<ApiPaginatedDto<GoalPayoutDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedDto<GoalPayoutDto>>>('/api/goals/payouts', {
      params: buildHttpParams(request),
    });
  }

  public getPayout(id: string): Observable<ApiResponseDto<GoalPayoutDto>> {
    return this.http.get<ApiResponseDto<GoalPayoutDto>>(`/api/goals/payouts/${id}`);
  }

  public confirmPayout(id: string): Observable<ApiResponseDto<GoalPayoutDto>> {
    return this.http.put<ApiResponseDto<GoalPayoutDto>>(`/api/goals/payouts/${id}/confirm`, {});
  }
}
