import { CommonModule } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { ApiModule } from '@app/core/api';
import { AuthModule } from '@app/core/auth/auth.module';
import { MediaObserverModule } from '@app/core/media-observer/media-observer.module';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { ReloadControlModule } from '@app/core/reload-control/reload-control.module';
import { SidenavModule } from '@app/core/sidenav/sidenav.module';
import { SnackBarModule } from '@app/core/snack-bar/snack-bar.module';
import { UpdaterModule } from '@app/core/updater/updater.module';
import { GoalCountersStateModule } from '@app/features/goal/goal-counters-state/goal-counters-state.module';
import { GoalStatusFlowModule } from '@app/features/goal/goal-status-flow';
import { NewsComplaintStateModule } from '@app/features/news/news-complaint-state/news-complaint-state.module';
import { ru } from 'date-fns/locale';

import { APP_SVG_ICON_PATH } from './tokens/app-svg-icon-path.token';

const MAT_FORM_FIELD_OPTIONS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  imports: [
    CommonModule,
    UpdaterModule,
    ReloadControlModule,
    SnackBarModule,
    SidenavModule,
    NavigationModule,
    ApiModule.forRoot(),
    AuthModule.forRoot(),
    MediaObserverModule.forRoot({
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
      xxxl: 1600,
    }),
    GoalStatusFlowModule.forRoot(),
    NewsComplaintStateModule.forRoot(),
    GoalCountersStateModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'RUB',
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: ru,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: MAT_FORM_FIELD_OPTIONS,
    },
    {
      provide: APP_SVG_ICON_PATH,
      useValue: '/assets/icons/svg',
    },
  ],
})
export class CoreModule {}
